@import '../node_modules/quill/dist/quill.core.css';
 @import '../node_modules/quill/dist/quill.bubble.css';
 @import '../node_modules/quill/dist/quill.snow.css'; 
 
//timline scheduler
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';

// SK on 03/09/21 css for Ng Select
@import "~@ng-select/ng-select/themes/default.theme.css";
// SK on 03/09/21 css for Ng Select

//treview checkbox
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import "~bootstrap/dist/css/bootstrap.css";
@import "~angular-calendar/css/angular-calendar.css";
@import "./assets/plugins/intl-tel-input/intl-tel-input.min.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "./assets/sass/style.angular.scss";
@import "./assets/sass/vendors/angular/material/angular-material";
@import "./assets/sass/vendors/angular/material/include";

@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";


@import '~@angular/material/theming';


$mat-myapp-primary: (
    50 : #dce1fa,
    100 : #bac2f0,
    200 : #adb6e7,
    300 : #8290e2,
    400 : #586acf,
    500 : #3f51b5,
    600 : #3545a3,
    700 : #215292,
    800 : #1f457e,
    900 : #0d2d5e,
    A100 : #ffffff,
    A200 : #e2efff,
    A400 : #afcfff,
    A700 : #96b7ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-myapp-accent: (
    50 : #fffaed,
    100 : #fff4d1,
    200 : #ffecb3,
    300 : #ffe494,
    400 : #ffdf7d,
    500 : #faa719,
    600 : #e79b17,
    700 : #d89116,
    800 : #c48315,
    900 : #b47913,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #fff5e1,
    A700 : #ffedc8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$primary: mat-palette($mat-myapp-primary);
$accent: mat-palette($mat-myapp-accent);

$theme: mat-light-theme($primary, $accent);

@include angular-material-theme($theme);
:root {
    --darkHeaderBackColor: #121212;
    --darkBackColor: #252423;
    --darkLinkButtoncolor: rgba(114, 153, 255, 1);
    --linkButtonColor: #00118E;  //3f51b5;
    --darkButtonDisabledLinkColor: rgba(114, 153, 255, 0.38);
    --buttonHoverColor: #2A2B2C; // rgb(82, 82, 94);
    --darkTextColor: rgba(255, 255, 255, 0.95);
    --darkBorderColor: rgba(255, 255, 255, 0.2);
    --darkMatRowHoverBackColor: #474646;
    --linkButtonHoverColor: #ECF0F9;
    --hyperlinkHoverTextColor: #0033cc;
    --hyperlinkHoverTextColor: #809fff;
    --matRowBorderColor: #70707030;
    --matRowHoverBackColor: #f1f7ff;
    --alertopsOrange: #FF9800;
    --darkTabBackColor: #313131;
    --editModeInputTextColor: rgba(0, 0, 0, 0.90);
    --formLabelTextColor: rgba(0, 0, 0, 0.75);
    --formValueTextColor: rgba(0, 0, 0, 0.85);
    --darkEditModeInputTextColor: rgba(255, 255, 255, 0.90);
    --darkAccordionBackColor: #33252423;
    --darkPlaceholderColor: rgba(255, 255, 255, 0.5);
    --scrollbarBackColor: #e1e1e1;
    --scrollbarColor: #2c2f3e;
    --darkScrollbarBackColor : #52504f;
    --darkScrollbarColor: #333333;
    --darkSmallAddBackColor: #c0d2ff; // #a5beff;
    --smallAddBackColor: #dce1ff; //#D8E3FF;
    --escActiveSectionBack: #f4f3ff;
    --darkEscActiveSectionBack: var(--darkTabBackColor);
}
.mandatory-star {
    color: red;
}
.validation-msg, .fv-help-block { // Reset pass, activate account page
    font-size: 12px;
}
.dropdown-toggle::after {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}
.greybox {
    background-color: #f2f2f2;
    border: none;
}
li.menu-item.menu-item-rel.ng-star-inserted.menu-item-active a.active,
li.menu-item a:hover,
li.menu-item a:active { background: #446DBF25; color: var(--linkButtonColor) !important}
li.menu-item.menu-item-rel.ng-star-inserted.menu-item-active a.active > span {color: var(--linkButtonColor) !important; }
li.menu-item.menu-item-rel.ng-star-inserted.menu-item-active a.active .menu-arrow {color: var(--linkButtonColor); }
.blackicon .svg-icon.svg-icon-primary svg g [fill] {     fill: #000 !important; }
.mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay, .mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
    opacity: 0 !important;
}
.b-bottom {
    border-bottom: 1px solid #eaeaea;
}
.height60 {
    height: 60px;
}
@media screen and (max-width: 480px) {
    .b-bottom {
        padding-bottom: 25px !important;
    }
}
@media (min-width: 1024px) and (max-width: 1299px) {
    .header-menu .menu-nav > .menu-item > .menu-link .menu-text {
        font-size: 13px;
    }
    .max-h-40px {
        max-height: 34px !important;
    }
    .left-navigation-link{
        padding: 20px 8px 15px !important;
    }
}
mat-error {
    font-size: 10px;
}
  .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item.menu-item-active > .menu-link,
  .topbar-item .btn-icon:hover {
    background-color: var(--linkButtonHoverColor) !important;
}

.header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item > .menu-link:hover {
    background-color: var(--linkButtonHoverColor) !important;
}

.header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item.menu-item-active > .menu-link .menu-text {
    color:var(--linkButtonColor) !important;
}
.header-fixed .header-menu .menu-nav > .menu-item:hover:not(.menu-item-here):not(.menu-item-active) > .menu-link, 
.header-fixed .header-menu .menu-nav > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) > .menu-link, 
.header-fixed .header-menu .menu-nav > .menu-item.menu-item-here > .menu-link, .header-fixed .header-menu .menu-nav > .menu-item.menu-item-active > .menu-link,
.right-top-menu .navi-item:hover {
    background-color: var(--linkButtonHoverColor) !important;
}
.header-menu .menu-nav > .menu-item:hover:not(.menu-item-here):not(.menu-item-active) > .menu-link .menu-text, 
.header-menu .menu-nav > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) > .menu-link .menu-text, 
.header-menu .menu-nav > .menu-item.menu-item-here > .menu-link .menu-text, .header-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
    color: var(--linkButtonColor) !important;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, 
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element{
    background: var(--linkButtonColor);
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, 
.mat-checkbox-checked.mat-primary .mat-checkbox-background { //Checkbox background
    background-color: var(--linkButtonColor);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    background: var(--linkButtonColor);
    color: #fff;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard, 
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard.mat-button-toggle-checked,
.mat-list-item-disabled .add-mem-role {
    opacity: 0.6;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.mat-pseudo-checkbox {
    border-color: var(--linkButtonColor);
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, 
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), 
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, 
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
    background-color: var(--linkButtonColor);
}
.mat-pseudo-checkbox-checked, 
.mat-pseudo-checkbox-indeterminate, 
.mat-accent .mat-pseudo-checkbox-checked, 
.mat-accent .mat-pseudo-checkbox-indeterminate {
    background: var(--linkButtonColor) !important;
}
//Ebison For modal Add btn and search fields design
.add-remove-members-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-border {
        display: block;
        height: 38px;
        width: 46px;
        margin-bottom: 2px;
        padding: 10px;
    }
}
.search-icon.search-ug-field {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    .mat-icon {
        height: 18px;
        width: 35px;
        font-size: 23px !important;
    }
}
@media screen and (min-width: 1800px) {
    .cdk-overlay-pane{
        height: auto !important;
    }
}
.add_edit_shift_dialog {
    overflow: auto;
}
.mat-button-focus-overlay { 
    background-color: transparent!important; 
}

.bg-green, .bg-red {
    line-height: 35px;
}
// SK on 03/09/21 css for Ng Select
.form-control .ng-select-container {
    border: none !important;
    top: -6px;
}
.form-control .ng-select-container:hover {
    box-shadow: none;
}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    box-shadow: none;
}
.filtersList .form-control.form-control-solid .ng-select-container {
    color: var(--editModeInputTextColor);
}
// SK on 03/09/21 css for Ng Select
// SK on 13/09/21 css for Quickstart
.quick-start-section {
    .mat-progress-bar-fill:after {
        background-color: var(--alertopsOrange) !important;
    }
    .mat-progress-bar-buffer {
        background-color: #e9ecef !important;
    }
    .separate-dial-code {
        width: 100%;
    }
    .separate-dial-code {
        input {
            max-width: 100%;
        }
    }
}
.alertops-orange {
    color: var(--alertopsOrange);
}
.breadcrumb-icon {
    color: var(--alertopsOrange);
    margin-right: 10px;
    font-size: 24px !important;
}
.navigation-row:hover {
    cursor: pointer;
}
// SK on 13/09/21 css for Quickstart

@mixin text-color-dark {
    color: var(--darkTextColor) !important;
}
%text-color-dark {
    @include text-color-dark()
}

@mixin back-color-dark {
    background-color: var(--darkBackColor) !important;
}
%back-color-dark {
    @include back-color-dark()
}
.dark-theme {
    @extend %back-color-dark;
    li.menu-item.menu-item-rel.ng-star-inserted.menu-item-active a.active,
    li.menu-item a:hover,
    li.menu-item a:active { background: #446DBF25; color: var(--darkTextColor) !important}
    li.menu-item.menu-item-rel.ng-star-inserted.menu-item-active a.active > span {color: var(--darkTextColor) !important; }
    li.menu-item.menu-item-rel.ng-star-inserted.menu-item-active a.active .menu-arrow {color: var(--darkTextColor); }
    .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item.menu-item-active > .menu-link,
    .right-top-menu .navi-item:hover {
        background-color: var(--buttonHoverColor) !important;
    }
    
    .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item > .menu-link:hover,
    .topbar-item .btn-icon:hover {
        background-color: var(--buttonHoverColor) !important;
    }
    
    .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item.menu-item-active > .menu-link .menu-text {
        color: var(--darkTextColor) !important;
    }
    .header-fixed .header-menu .menu-nav > .menu-item:hover:not(.menu-item-here):not(.menu-item-active) > .menu-link, 
    .header-fixed .header-menu .menu-nav > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) > .menu-link, 
    .header-fixed .header-menu .menu-nav > .menu-item.menu-item-here > .menu-link, .header-fixed .header-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
        background-color: var(--buttonHoverColor) !important;
    }
    .header-menu .menu-nav > .menu-item:hover:not(.menu-item-here):not(.menu-item-active) > .menu-link .menu-text, 
    .header-menu .menu-nav > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) > .menu-link .menu-text, 
    .header-menu .menu-nav > .menu-item.menu-item-here > .menu-link .menu-text, .header-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
        color: var(--darkTextColor) !important;
    }
    
    .header-menu .menu-nav > .menu-item.menu-item-open-dropdown > .menu-link .menu-text, .header-menu .menu-nav > .menu-item.menu-item-open-dropdown > .menu-link .menu-text {
        color: var(--darkTextColor) !important;
    }
    .login {
        @extend %text-color-dark;
        @extend %back-color-dark;
    }
    .subheader, .create-inbound-integration-wrapper, .outbound-integration-wrapper, 
    .alert-details-breadcrumb, .create-new-alert-breadcrumb, .message-detail-breadcrumb {
        .b-bottom {
            border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
        }
    }
    #kt_header {
       background-color: var(--darkHeaderBackColor);
       box-shadow: none;
    }
    @media (min-width: 992px) {
        .header-menu {
            .menu-nav {
                .menu-item {
                    .menu-link.menu-toggle:hover {
                        .menu-text, .menu-arrow {
                            color: var(--darkTextColor) !important;
                        }
                    }
                    .menu-link {
                        .menu-text, .menu-arrow {
                            // color: #c3c3c3 !important;
                            @include text-color-dark;
                        }
                    }
                    .menu-link.active, .menu-link:hover {
                        .menu-text, .menu-arrow {
                            color: var(--darkTextColor) !important;
                        }
                    }
                    .menu-submenu {
                        @include back-color-dark;
                        .menu-subnav {
                            .menu-item {
                                .menu-link {
                                    .menu-text {
                                        // color: #c3c3c3 !important;
                                        @include text-color-dark;
                                    }
                                }
                                .menu-link.active, .menu-link:hover {
                                    .menu-text, .menu-arrow {
                                        color: var(--darkTextColor) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .help-icon {
        // color: #c3c3c3 !important;
        @extend %text-color-dark;
    }
    .topbar-item {
        span {
            // color: #c3c3c3 !important;
            @extend %text-color-dark;
        }
    }
    .topbar {
        .dropdown-menu {
            @extend %back-color-dark;
            // color: #c3c3c3;
            @extend %text-color-dark;
            border: 0.3px solid var(--darkBorderColor);
            .separator.separator-solid {
                border-color: var(--darkBorderColor);
            }
            .navi-separator {
                border-color: var(--darkBorderColor);
            }
        }
    }
    .navi {
        .navi-item {
            .navi-link {
                .navi-text {
                    @extend %text-color-dark;
                }
            } 
        } 
    }
    .text-size-20:last-child, .text-size-16, .text-last-bm, .bg-black, .editIcon,
    .text-size-20, .text-block, .group-name, .integration-name,
    .mat-expansion-panel-header-title, .acc-ins-title, .mat-accent,
    .mat-expansion-panel-header-description, .mat-expansion-indicator::after, .form-control.form-control-solid,
    .mat-list-base .mat-list-item,
    .mat-list-item-content, .tagvalue, ngx-intl-tel-input,
    .service-incident-edit-main  { //navigation arrow (.bg-black) // .mat-raised-button -removed
        @extend %text-color-dark;
    }
    .mat-list-item {
        .mat-list-item-content {
            .member-role-name {
                @extend %text-color-dark;
            }
        }
    }
    .mat-ver-stepper{
        .mat-list {
            .mat-list-item:hover {
                background-color: var(--darkMatRowHoverBackColor) !important;
            }
            .mat-list-item.active{
                background-color: var(--darkTabBackColor) !important;
            }
        }
    }
    .step-left-card {
        .mat-card, .mat-card:hover {
            background-color: var(--darkTabBackColor) !important;
        }
    }
    .integration-search {
        .mat-icon {
            @extend %text-color-dark;
        }
    }
    button.btn.btn-link.btn-sm.ng-star-inserted.ngbUp, button.btn.btn-link.btn-sm.ng-star-inserted.ngbdown {
        border-color: #c3c3c3 !important;
        color: var(--darkLinkButtoncolor) !important;
        opacity: 0.4;
        &:hover {
            background: none !important;
            border-color: var(--darkMatRowHoverBackColor) !important;
            opacity: 1;
        }
    }
    
    .filter-btn.form-control {
        border-color: #c3c3c3 !important;
    }
    .filter-btn {
        border-color: #c3c3c3 !important;
        @extend %text-color-dark;
    }
    .filter-btn.btn-active, .filter-btn.active,
    .btn-border.active {
        color: var(--darkLinkButtoncolor) !important;
        border-color: var(--darkLinkButtoncolor) !important;
    }
    .filter-btn:hover {
        background-color: var(--darkMatRowHoverBackColor) !important;
        border-color: var(--darkMatRowHoverBackColor) !important;
    }
    .btn-border.active .mat-icon, .blu-icon, .group-schedule .menu-icon {
        color: var(--darkLinkButtoncolor) !important;
    }
    .btn-color {
        background-color: transparent !important;
        color: var(--darkLinkButtoncolor) !important;
    }
    .mat-select.filter-btn, .mat-select.filter-btn:hover {
        background-color: transparent !important;
        .mat-select-value, .mat-select-arrow {
            @extend %text-color-dark;
        }
    }
    .btn-blue {
        @extend %text-color-dark;
        border-color: #c3c3c3 !important;
    }
    .mat-select-panel {
        @extend %back-color-dark;
        border: 1px solid var(--darkBorderColor);
        .mat-option {
            @extend %text-color-dark;
        }
        .mat-option:hover {
            background: var(--buttonHoverColor);
        }
    }
    .card {
        background-color: transparent !important;
        @extend %text-color-dark;
    }
    .card-header {
        background-color: transparent !important;
        @extend %text-color-dark;
        .card-title {
            @extend %text-color-dark;
        }
    }
    .mat-table {
        @extend %back-color-dark;
        @extend %text-color-dark;
        .mat-header-row {
            @extend %back-color-dark;
        }
        .mat-row {
            @extend %back-color-dark;
            box-shadow: 0 0px 3px 1px #7c7c7c69 !important;
        }
        .mat-row:hover {
            background-color: var(--darkMatRowHoverBackColor) !important;
            box-shadow: none !important;
        }
        .mat-header-row {
            border-bottom: 1px solid var(--darkBorderColor);
        }
        .mat-header-cell {
            a {
                @extend %text-color-dark;
            }
            @extend %text-color-dark;
        }
        .mat-cell {
            a {
                @extend %text-color-dark;
            }
            @extend %text-color-dark;
        }
    }

    .mat-form-field-infix, .mat-form-field-flex {
        @extend %back-color-dark;
        border-color: #c3c3c3 !important;
        @extend %text-color-dark;
    }
    
    .quick-start-section, .group-contact-disp {
        .mat-form-field-infix {
            border-color: transparent !important;
            .mat-select {
                border: 1px solid var(--darkBorderColor) !important;
            }
        }
        .mat-form-field-infix, .mat-form-field-flex {
            background-color: transparent !important;
        }
    }
    .mat-step-header {
        .mat-step-label.mat-step-label-active {
            @extend %text-color-dark;
        }
        .mat-step-label, .mat-step-optional {
            color: #828282;
        }
    }
    .mat-form-field-infix {
        border: 1px solid #c3c3c3 !important;
        .mat-select {
            border: none !important;
        }
    }
    .group-schedule-details {
        .mat-form-field-infix {
            border: none !important;
        }
    }
    .method-edit {
        .mat-form-field-infix {
            border: none !important;
        }
    }
    .timeline-wrapper {
        .mat-form-field-infix {
            border: none !important;
        }
        .mat-form-field-flex {
            border-bottom: 1px solid #c3c3c3 !important;
        }
    }
    .mat-tab-group.mat-primary, .mat-tab-nav-bar.mat-primary {
        .mat-ink-bar {
            background-color: var(--darkLinkButtoncolor);
        }
    } 
    ::ng-deep {
        .mat-form-field-infix, .mat-select-search-inner {
            ::placeholder {
                color: rgba(255, 255, 255, 0.38);
                opacity: 1; /* Firefox */
            }
            :-ms-input-placeholder { /* Internet Explorer 10-11 */
               color: rgba(255, 255, 255, 0.38);
            }
            ::-ms-input-placeholder { /* Microsoft Edge */
               color: rgba(255, 255, 255, 0.38);
            }
            ::-webkit-input-placeholder { /* Edge */
                color: rgba(255, 255, 255, 0.38);
            }
        }
    }
    .mat-input-element::-webkit-input-placeholder, .mat-checkbox-disabled .mat-checkbox-label {
        color: #828282 !important;
        box-sizing: border-box !important;
    }
    .mat-datepicker-toggle {
        @extend %text-color-dark;
    }
    .mat-select, .mat-select-value, .highcharts-container {
        background-color: transparent !important;
    }
    .mat-select {
        .mat-select-placeholder {
            @extend %text-color-dark;
            -webkit-text-fill-color: #828282 !important;
        }
        .mat-select-value span {
            @extend %text-color-dark;
        }
    }
    .mat-select-value-text {
        @extend %text-color-dark;
    }
    .mat-select-arrow {
        @extend %text-color-dark;
        opacity: 1 !important;
    }
    .clear_btn, .select-link, .integration-guide, .group-schedule .list-link {
        @extend %text-color-dark;
    }
    .highcharts-background, .highcharts-tooltip-box {
        fill: var(--darkBackColor) !important;
    }
    .highcharts-background {
        stroke: rgba(255, 255, 255) !important;
        stroke-width: 0.3;
    }
    .charts.card {
        .highcharts-background {
          stroke: unset !important;
        }
      }
    .highcharts-container text {
        fill: #c3c3c3 !important;
    }
    .highcharts-data-label {
        text {
            fill: var(--darkBackColor) !important
        }
    }
    .header-logo {
        background-color: var(--darkHeaderBackColor) !important;
        box-shadow: none !important;
        img {
            content: url(../src/assets/media/logos/logo-white.png);
        }
    }
    .our-logo {
        content: url(../src/assets/media/logos/logo-white.png);
    }
    .login-form {
        .form-control.form-control-solid {
            border-color: #c3c3c3 !important;
        }
    }
    .envelope-btn {
        color: var(--darkLinkButtoncolor) !important;
        i, a {
            color: var(--darkLinkButtoncolor) !important;
        }
    }
    .mat-tab-list {
        background-color: var(--darkTabBackColor) !important;
        .mat-tab-label {
            @extend %text-color-dark;
        }
        .mat-tab-label-active {
            color: var(--darkLinkButtoncolor) !important;
            opacity: 1;
        }
    }
    .timeline-wrapper .time, .add-shift, .group-schedule .list-link, .service-status-subscriber-main, .right-side-preview {
        background-color: var(--darkTabBackColor) !important;
    }
    .mat-comp-list, .escalation-policy-member-role-section .mat-ver-stepper .mat-list {
        .mat-list-item.active {
            background: var(--darkTabBackColor) !important;
        }
    }
    .mat-datepicker-content {
        background-color: var(--darkTabBackColor) !important;
        @extend %text-color-dark;
        .mat-calendar-table-header, .mat-calendar-body-label {
            @extend %text-color-dark;
        }
        .mat-calendar-arrow, .mat-datepicker-toggle, .mat-calendar-next-button, 
        .mat-calendar-previous-button, .mat-calendar-body-cell-content, .mat-date-range-input-separator {
            color: rgba($color: #FFF, $alpha: 0.54) !important;
        }
        .mat-calendar-arrow {
            fill: rgba($color: #FFF, $alpha: 0.44);
        }
        .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
            border-color: var(--darkBorderColor) !important;
        }
        .mat-calendar-table-header-divider::after {
            background: var(--darkBorderColor);
        }
    }
    .mat-datepicker-content {
        background-color: var(--darkTabBackColor) !important;
        @extend %text-color-dark;
        .mat-calendar-table-header, .mat-calendar-body-label {
            @extend %text-color-dark;
        }
        .mat-calendar-arrow, .mat-datepicker-toggle, .mat-calendar-next-button, 
        .mat-calendar-previous-button, .mat-calendar-body-cell-content, .mat-date-range-input-separator {
            color: rgba($color: #FFF, $alpha: 0.54) !important;
        }
        .mat-calendar-arrow {
            fill: rgba($color: #FFF, $alpha: 0.44);
        }
        .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
            border-color: var(--darkBorderColor) !important;
        }
        .mat-calendar-table-header-divider::after {
            background: var(--darkBorderColor);
        }
    }
    .email-title {
        .btn-link {
            .mat-icon {
                margin-right: 5px;
            }
        }
    }
    .mat-select-search-inner {
        @extend %back-color-dark;
    }
    .mat-checkbox-frame, .mat-pseudo-checkbox {
        border-color: var(--darkLinkButtoncolor) !important;
    }
    .mat-stepper-horizontal-line {
        border-color: #c3c3c3 !important;
    }
    .bg_color, .tagcard, .group-customfields, .form-control.form-control-solid,
    .tagsData .mat-card, .timeline-wrapper .users-name {
        background-color: transparent !important;
        border-color: var(--darkBorderColor) !important;
    }
    .summarylist {
        .labelwidth {
            color: #828282 !important;
        }
        .fieldwidth {
            @extend %text-color-dark;
        }
    }
    .taglabel {
        color: #828282 !important;
    }
    .create-alert {
        .group-name{
            span{
                @extend %back-color-dark;
            }
            
        }
        //Ebison on 11/Feb/2022 -Dark theme - Go to Alert messsage in Create alert page 
        .success-message {
            .alert-success {
                color: #d4edda !important;
                background-color: #155724 !important;
            }
            .goto_alert {
                border: 1px solid #d4edda !important;
                color: #155724 !important;
            }
            .mat-raised-button {
                color: #d4edda !important;
            }
        }
    }
    .countfields {
        .mat-icon, span {
            @extend %text-color-dark;
        }
    }
    .mat-card {
        background-color: rgba($color: #000000, $alpha: 0.2) !important;
        @extend %text-color-dark;
        .form-control {
            border-color: var(--darkBorderColor) !important;
        }
    }
    .mat-card:hover, .mat-expansion-panel {
        background-color: rgba($color: #000000, $alpha: 0.5) !important;
    }
    .greybox.form-control { //Search input
        background-color: transparent !important;
        border: 0.5px solid #c3c3c3 !important;
    }
    .mat-icon-button.mat-button-disabled.mat-button-disabled {
        color: #646464 !important;
    }
    .group-name-info span {
        @extend %back-color-dark;
        @extend %text-color-dark;
    }
    .title-name, .head-name, .text-dark, .highcharts-title, .mat-date-range-input-separator, .info-icon {  //no fields
        color: rgba(255, 255, 255, 0.75) !important
    }
    .mat-dialog-container {
        @extend %back-color-dark;
        @extend %text-color-dark;
    }
    .ngb-tp-input-container {
        button {
            @extend %text-color-dark;
        }
    }
    .form-control, .notes_textarea {
        background-color: transparent !important;
        @extend %text-color-dark;
    }
    .form-value {
        background-color: transparent;
        @extend %text-color-dark;
    }
    .card-label::after { // Title Underline
        background: var(--alertopsOrange) !important;
    }
    .mat-icon-button {
        border-color: #c3c3c3 !important;
        .mat-button-wrapper {
            i {
                @extend %text-color-dark;
            }
        }
    }
    .mat-radio-button, .service-status-maintenance-edit-main, 
    .filters-match-api-inbound-wrapper .filter-form .dynamic-filter-field.field label, 
    .filters-match-email-inbound-wrapper .filter-form .dynamic-filter-field.field label {
        @extend %text-color-dark;
    }
    .mat-icon.step-icon:before, .step-list span.step-numeric:before,
    .mat-icon.step-icon:after, .step-list span.step-numeric:after {
        background: #c3c3c3 !important;
    }
    .e-treeview .e-list-text,
    .e-treeview .e-icon-collapsible, .e-treeview .e-icon-expandable {
        @extend %text-color-dark;
    }
    .e-treeview .e-list-item.e-hover {
        .e-list-text {
            color: var(--darkTextColor) !important;
        }
    }
    .e-treeview .e-list-item.e-hover > .e-text-content .e-icon-collapsible, .e-treeview .e-list-item.e-hover > .e-text-content .e-icon-expandable, 
    .next-steps-list li, .quick-final-step, .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
        color: var(--darkTextColor) !important;
    }
    .mat-stepper-horizontal, .mat-stepper-vertical {
        background-color: transparent;
    }
    .mat-expansion-panel {
        background-color: rgba(0, 0, 0, 0.25) !important;
        box-shadow: none !important;
    }
    .mat-expansion-panel-header {
        background-color: var(--darkAccordionBackColor) !important;
        &:hover {
            background-color: var(--darkMatRowHoverBackColor) !important;
        }
    }
    .sample-fields {
        .edit-box {
            background-color: var(--darkMatRowHoverBackColor) !important;
        }
    } 
    mwl-calendar-month-view, mwl-calendar-week-view, mwl-calendar-day-view {
        color: var(--darkBackColor);
    }
    quill-editor {
        color: #fff;
    }
    .ql-editor.ql-blank::before {
        font-weight: 500 !important;
        caret-color: var(--darkTextColor) !important;
        @extend %text-color-dark;
        -webkit-text-fill-color: #828282;
    }
    .dark-no-border, .opt-radio-chips {
        .mat-cell, .mat-form-field-infix {
            border: none !important;
        }
    }
    .opt-radio-chips {
        .mat-form-field-infix, .mat-form-field-flex {
            background-color: transparent !important;
        }
    }
    .btn-link.mat-select {
        .mat-select-value {
            span {
                color: var(--darkLinkButtoncolor) !important;
                -webkit-text-fill-color: var(--darkLinkButtoncolor) !important;
            }
        }
    }
    .mat-slide-toggle.mat-primary.mat-checked {
        .mat-slide-toggle-bar {
            background-color: rgba(114, 153, 255, 0.54) !important;
        }
        .mat-slide-toggle-thumb {
            background-color: var(--darkLinkButtoncolor) !important;
        }
    }
    .mat-slide-toggle.mat-primary {
        .mat-slide-toggle-bar {
            background-color: rgba(244, 244, 244, 0.3) !important;
        }
    }
    .footer-design {
        background-color: transparent !important;
    }
    .from-to-date-range-input-separator { // .custom-date-input  {
        color: #c3c3c3 !important;
    }
    .customDatepickerDashboard {
        .mat-form-field-infix {
            border-color:  rgba(114, 153, 255, 0.7) !important;
        }
    }
    .custom-date-input {
        color: var(--darkLinkButtoncolor) !important;
    }
    .custom-date-input:focus {
        color: var(--darkLinkButtoncolor) !important;
        border-color: var(--darkBorderColor) !important;
    }
    .font-normal, .subscription-page, .plan-section label, 
    .billing-section label, .billing-page, .access-table-list,
    .outbound-integration-wrapper, .trial-expire-label {
        color: var(--darkTextColor) !important;
    }
    .incident-detail {
        border-color: var(--darkBorderColor) !important;
        .table {
            color: var(--darkTextColor);
            tr {
                border-bottom: 0.5px solid rgb(255, 255, 255, 0.1) !important;
            }
        }
        &:hover {
            background-color: var(--darkMatRowHoverBackColor) !important;
        }
    }
    .e-treeview {
        .e-list-item.e-active,  .e-list-item.e-hover {
            background: var(--darkMatRowHoverBackColor) !important;
        }
        .e-list-item.e-hover > .e-fullrow {
            background-color: var(--darkMatRowHoverBackColor) !important;
            border-color: var(--darkMatRowHoverBackColor) !important;;
        }
    }
    .btn-add {
        color: var(--darkLinkButtoncolor) !important; // #7299FF;
        .icon-edit {
            color: var(--darkLinkButtoncolor);
        }
    }
    .btn-add:hover, .text-dark-blue:hover {
        background: var(--darkLinkButtoncolor) !important;
        color: var(--darkBackColor) !important;
        .icon-edit {
            color: var(--darkBackColor) !important;
        }
    }
    .table-scroll {
        .table-head-custom {
            .mat-slide-toggle.mat-primary.mat-checked {
                .mat-slide-toggle-thumb {
                    background-color: var(--darkLinkButtoncolor) !important;
                }
            }
            .mat-column-Action, .mat-column-action, .mat-column-actions, .mat-column-in_maintenance {
                .mat-icon {
                    color: var(--darkLinkButtoncolor) !important;
                }
                .mat-icon:hover {
                    color: #4879f8;
                }
                .split-button-toggle {
                    .mat-button-toggle-label-content {
                        .mat-icon {
                            color: #000 !important;
                        }
                    }
                }
            }
            .mat-button-disabled, .button-disabled {
                .mat-icon {
                    color: var(--darkButtonDisabledLinkColor) !important;
                }
            }
        }
        ::-webkit-scrollbar-track {
            background: var(--darkScrollbarBackColor) !important;
        }
          
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: var(--darkScrollbarColor) !important;
        }
          
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: var(--darkScrollbarColor) !important; 
        }
    }
    .table-scroll.non-clickable {
        mat-row:hover {
            background-color: #38383dc9  !important;
        }
    }
    //mat-select scroll bar
    .mat-select-panel, app-alert-delay-group-inbound, 
    .workflow-access-comp .mat-table, .api-body, .sso-crtificate-sec textarea, 
    .outbound-integration-method-wrapper .available-fields,
    .mat-dialog-container, .sample_field_scroll, textarea.form-control, 
    .table-head-custom, .right-side-preview .subscribers-table, .cdk-overlay-pane {
        /* Track */
        &::-webkit-scrollbar-track {
          background: var(--darkScrollbarBackColor) !important;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: var(--darkScrollbarColor);
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: var(--darkScrollbarColor); 
        }
    }
    .edit_icon, .usage-text, a.usage-text:hover, .mat-button-toggle, .group-schedule .resource-name {
        color: var(--darkLinkButtoncolor) !important;
    }
    .mat-form-field, .greybox.form-control, .mat-form-field-infix, 
    .mat-select, .form-control, .alert-group-box,
    .dark-theme-card {
        border-color: rgba(249,249,250,0.2) !important;
    }
    .table-list-no-records-msg {
        border-color: rgba(249,249,250, 0.2) !important;
        color: var(--darkPlaceholderColor);
    }
    .card, .alertdetails-tab-wrapper .alert-details-forms .alert-group-box,
    .quickstart-escalation .mat-ver-stepper .mat-list .mat-list-item.active .member-role-name { // darker than input border
        border-color: rgba(249,249,250,0.4) !important;
    }
    .quickstart-escalation {
        strong.model-title {
            color: var(--darkTextColor) !important;
        }
        .mat-ver-stepper .mat-list .mat-list-item.active .member-role-name {
            border-color: rgba(249, 249, 250, 0.4) !important;
        }
    }
    .step-list {
        .member-role-name, .form-control-disabled {
            border-color: rgba(249,249,250,0.2) !important;
        }
    } 
    .mat-list-item.active {
        .step-list {
            .member-role-name, .form-control-disabled {
                border-color: rgba(249, 249, 250, 0.6) !important;
            }
        }
    }
    .dark-theme-card {
        .numbers p {
            color: rgba(255, 255, 255, 0.65) !important;
        }
    }
    .cdk-overlay-dark-backdrop {
        background: rgba(0, 0, 0, 0.72);
    }
    .icon-search, .icon-clear-filter, .mat-icon-drag-indicator, .header_btn_color,
    #expand_less, #expand_more, .inside-content-field span, .icon-light, .deleteIcon {
        color: var(--darkLinkButtoncolor) !important;
    }
    .card-label::after {
        background: var(--darkLinkButtoncolor) !important;
    }
    .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, 
    .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
    .mat-checkbox-checked.mat-primary .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, 
    .mat-checkbox-checked.mat-primary .mat-checkbox-background {
        background: var(--darkLinkButtoncolor) !important;
    }
    .e-checkbox-wrapper .e-frame, .e-css.e-checkbox-wrapper .e-frame, .mat-radio-outer-circle,
    .btn-subscription,
    .mat-pseudo-checkbox {
        background: transparent;
        border-color: var(--darkLinkButtoncolor) !important;
    }
    .btn-subscription, .btn-cancel-subscription, .text-dark-blue {
        color: var(--darkLinkButtoncolor) !important;
    }
    .activate-account .activate-form,
    .login-signin, .login-forgot {
        .bg-button {
            background-color: var(--darkLinkButtoncolor) !important;
            // color: var(--darkBackColor) !important;
        }
    } 
    // .mat-pseudo-checkbox::after, .e-checkbox-wrapper .e-frame, .e-css.e-checkbox-wrapper .e-frame {
    //     color: #000 !important;
    // }
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-button-toggle-checked,
    .e-frame.e-check, .btn-subscription.active, .btn-subscription:hover,
    .alert-delay-group-create-inbound-wrapper .delay-group-switch span.active  { //Checkbox background
        background-color: var(--darkLinkButtoncolor) !important;
    }
    .mat-button-toggle-checked {
        color: var(--darkTextColor) !important;
    }
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle, .mat-button-toggle-group,
    .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
    .alert-delay-group-create-inbound-wrapper .delay-group-switch span,
    .esc-policy-timeline-prvw .entry .title:before {
        border-color: var(--darkLinkButtoncolor) !important;
    }
    .mat-radio-button.mat-accent .mat-radio-inner-circle, 
    .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), 
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, 
    .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
        background-color: var(--darkLinkButtoncolor) !important;
    }
    .mat-pseudo-checkbox-checked, 
    .mat-pseudo-checkbox-indeterminate, 
    .mat-accent .mat-pseudo-checkbox-checked, 
    .mat-accent .mat-pseudo-checkbox-indeterminate {
        background: var(--darkLinkButtoncolor) !important;
    }
    .subscription-rate, .subscription-total-amt, .stripe_badge, .service-list-tooltip,
    .subdomain-form .signin-text {
        color: var(--darkTextColor) !important;
    }
    .filterLabel, .allfilter, .alert-int-name { // Alert list page
        color: rgba(255, 255, 255, 0.45) !important;
    }
    .filtersList {
        .form-control.form-control-solid {
            .ng-select-container {
                background: transparent;
                color: var(--darkTextColor) !important;
            }
        }
    }
    .text-hover-black:hover {
        color: #809fff !important;
    }
    .custom-paginator-range-actions {
        .icon-arrow, .page-number, .pagin-text  {
            color: var(--darkLinkButtoncolor) !important;
        }
    }
    .bt-add-another.mat-button-disabled, .bt-border.mat-button-disabled,
    .bt-background.mat-button-disabled {
        background-color: rgba(0, 0, 0, 0.60) !important;
        color: rgba(255, 255, 255, 0.25) !important;
    }
    .group-schedule .mat-button.mat-button-disabled.mat-button-disabled {
        color: rgba(255, 255, 255, 0.25) !important;
    }
    .bg-green {
        background: #2C5F2D !important;
        color: #C9FFC5 !important; // 60% saturation from background
    }
    .bg-red {
        background: #990011 !important;
        color: #FFB7A1 !important; // 60% saturation from background
    }
    .breadcrumb-btn-box { //, .btnui {
        color: #000 !important;
        background-color: var(--darkSmallAddBackColor) !important;
    }
    .forgot-password, .btn-cancel-subscription {
        color: var(--darkLinkButtoncolor) !important;
    }
    .cal-week-view, .cal-month-view {
        background-color: transparent;
        color: var(--darkTextColor) !important;
    }
    .cal-month-view .cal-cell-row:hover,
    .cal-month-view .cal-cell-row .cal-cell:hover,
    .cal-month-view .cal-day-cell.cal-today:hover {
        background-color: var(--darkMatRowHoverBackColor);
    }
    .cal-month-view .cal-day-cell.cal-today:hover {
        .cal-cell-top {
            color: #000;
        }
    }
    .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
        color: red;
    }
    .cal-week-view .cal-header.cal-today,
    .cal-week-view .cal-day-headers .cal-header:hover,
    .cal-week-view .cal-header.cal-weekend:hover span{
        color: #000 !important;
    }
    .cal-week-view .cal-hour-odd {
        background-color: rgba(0, 0, 0, 0.45);
    }

    .mat-checkbox-checkmark, .mat-checkbox-checkmark-path,
    .mat-pseudo-checkbox::after, .e-frame.e-check {
        stroke: #000 !important;
        color: #000 !important;
    }
    .mat-checkbox-indeterminate .mat-checkbox-mixedmark {
        background-color: #000 !important;
    }
    .components_affected_value, .sample_field_scroll,
    .stepper-slide .slide-button a {
        background-color: rgba(0, 0, 0, 0.25) !important;
    }
    .stepper-slide .slide-button a {
        color: var(--darkLinkButtoncolor) !important;
        border-color: var(--darkLinkButtoncolor) !important;
        background-image: linear-gradient(to right, transparent 50%,  var(--darkLinkButtoncolor) 50%);
    }
    .stepper-slide.mat-step-card .slide-button a {
        background-image: linear-gradient(to left, transparent 50%,  var(--darkLinkButtoncolor) 50%);
    }
    .form-control-disabled, .mat-tab-list .mat-tab-label.mat-tab-disabled {
        color: rgba(255, 255, 255, 0.25) !important;
    }
    .esc-policy-timeline-prvw .timeline {
        background: rgba($color: #000000, $alpha: 0.15) !important;
    }
    .e-schedule .e-timeline-view .e-resource-left-td, .e-schedule .e-timeline-month-view .e-resource-left-td,
    .e-schedule .e-timeline-view .e-child-node, .e-schedule .e-timeline-month-view .e-child-node,
    .e-schedule .e-timeline-view .e-date-header-wrap table td, .e-schedule .e-timeline-month-view .e-date-header-wrap table td,
    .e-schedule .e-timeline-view .e-work-cells, .e-schedule .e-timeline-month-view .e-work-cells {
        background-color: rgba($color: #000000, $alpha: 0.9);
    }
    .e-schedule .e-timeline-view .e-resource-text, .e-schedule .e-timeline-month-view .e-resource-text,
    .e-schedule .e-timeline-view .e-date-header-wrap table td, .e-schedule .e-timeline-month-view .e-date-header-wrap table td {
        color: var(--darkTextColor);
    }
    .e-schedule .e-timeline-view .e-date-header-wrap table td.e-current-day, .e-schedule .e-timeline-month-view .e-date-header-wrap table td.e-current-day {
        color: #e3165b;
    }
    .e-schedule .e-timeline-view .e-resource-cells, .e-schedule .e-timeline-month-view .e-resource-cells,
    .e-schedule .e-timeline-view .e-date-header-wrap table td, .e-schedule .e-timeline-month-view .e-date-header-wrap table td,
    .e-schedule .e-timeline-view .e-work-cells, .e-schedule .e-timeline-month-view .e-work-cells,
    .e-schedule .e-timeline-view .e-resource-left-td, .e-schedule .e-timeline-month-view .e-resource-left-td {
        border-color: var(--darkBorderColor) !important;
    }
    .mat-menu-panel {
        background-color: var(--darkTabBackColor) !important;
        .mat-menu-item {
            color: var(--darkTextColor) !important;
            .mat-icon {
                color: var(--darkTextColor) !important;
            }
        }
        .mat-menu-item:hover {
            background: var(--darkMatRowHoverBackColor) !important;
        }
    }
    .mat-autocomplete-panel, .dropdown-menu {
        background-color: var(--darkTabBackColor) !important;
        .mat-option, .dropdown-item {
            color: var(--darkTextColor) !important;
        }
    }
    .dropdown-menu .dropdown-item:hover {
        background-color: rgba($color: #000000, $alpha: 0.5);
    }
    input, textarea {
        caret-color: var(--darkTextColor) !important;
    }
    .bt-background {  // submit button
        background: #fff !important;
        color: #2C2F3E !important;
    }
    .bt-border { // cancel button
        background: #2C2F3E !important;
        color: #fff !important;
    }
    .mat-button-toggle-checked.mat-button-toggle-appearance-standard.btnui,
    .mat-button-toggle-appearance-standard.btnui,
    .quick-start-section .btnui {
        background: var(--darkSmallAddBackColor) !important;
        color: #000 !important;
        &:hover {
            background: #acc9fb !important;
        }
    }
    .mat-button-toggle-group-appearance-standard.split-button-toggle .mat-button-toggle + .mat-button-toggle {
        border-color: #000 !important;
    }
    .service-list-form  {
        .example-detail-row {
            box-shadow: unset !important;
        }
        .example-detail-row:hover {
            background-color: unset !important;
        }
        .highcharts-background {
            stroke: none !important;
            fill: none !important;
        }
    }
    .dy-header-field {
        color: var(--darkEditModeInputTextColor) !important;
    }
    .split-button-toggle {
        border-color: #C9DDFF !important;
    }
    .edit-box-md, .btnui {
        background-color: var(--darkSmallAddBackColor) !important;
    }
    .split-btn:disabled {
        opacity: 0.4;
    }
    .drag-indicator.disable-drag-indicator { 
        color: var(--darkButtonDisabledLinkColor) !important;
    }
    ::-webkit-input-placeholder {
        color: var(--darkPlaceholderColor);
    }
    :-moz-placeholder { /* Firefox 18- */
        color: var(--darkPlaceholderColor);  
    }
    ::-moz-placeholder {  /* Firefox 19+ */
        color: var(--darkPlaceholderColor);  
    }
    :-ms-input-placeholder {  
        color: var(--darkPlaceholderColor);  
    }
    .temp-card {
        &::-webkit-scrollbar {
            width: 5px;
            max-height:5px; 
            height: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: var(--darkScrollbarBackColor) !important;
          max-height:5px; 
          height: 5px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: var(--darkScrollbarColor) !important;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: var(--darkScrollbarColor) !important; 
        }
    }
    // Added for Darktheme matdatepicker disabled style
    .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after{
        color: #646464 !important;
    }
    .ngb-disabled-color {
        input {
            color: #646464 !important;
        }
    }
    .cal-week-view .cal-header.cal-weekend span {
        color: var(--darkTextColor) !important;
    }
    .link-text{
        a {
            color: var(--darkTextColor) !important;
        }a:hover{
            color: var(--darkLinkButtoncolor) !important;
        }
    }
}
.light-theme {
    background-color: #fff;
    .btn-blue { //schedule page
        color: var(--linkButtonColor) !important;
    }
    .filter-btn.active {  //schedule page
        color: var(--linkButtonColor) !important;
    }
    .text-hover-black:hover {
        color: #0033cc !important;
    }
    .quickstart-escalation .mat-ver-stepper .mat-list .mat-list-item.active .member-role-name  {
        border-color: #000 !important;
    }
    .quick-start-section {
        .light-margin.title-name {
            margin-top: 12px;
        }
    }
    .mat-list-item.active {
        .step-list {
            .member-role-name, .form-control-disabled {
                border-color: rgba(0, 0, 0, 0.6) !important;
            }
        }
    }
    .mat-tab-list {
        .mat-tab-label-active {
            color: var(--linkButtonColor) !important;
            opacity: 1;
        }
    }
    .table-head-custom {
        .mat-button-disabled, .button-disabled {
            .mat-icon {
                color: rgba(63, 81, 181, 0.26) !important;
            }
        }
    }
    .drag-indicator.disable-drag-indicator{
        color: rgba(63, 81, 181, 0.26) !important;
    }
}

mat-table {
    border-collapse: separate;
    border-spacing: 0 15px;
    mat-row.mat-row:first-child td:first-child { border-top-left-radius: 10px; }
    mat-row.mat-row:first-child td:last-child { border-top-right-radius: 10px; }
    mat-row.mat-row:last-child td:first-child { border-bottom-left-radius: 10px; }
    mat-row.mat-row:last-child td:last-child { border-bottom-right-radius: 10px; }
    mat-row.mat-row{
        padding: 0 10px;
        box-shadow: 0 0px 3px 1px var(--matRowBorderColor);
        overflow: hidden;
        margin-top: 10px;
        border-radius: 10px;
        mat-cell{
            border: none;
            white-space: nowrap;
            font-family: Roboto, 'Helvetica Neue', sans-serif !important;
            font-size: 14px !important;
        }
    }
    mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type { padding-left: 0px; }
    mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type { padding-right: 0px;}
    mat-header-row{
        padding: 0 10px;
    }

    .mat-cell{
        font-family: Roboto, 'Helvetica Neue', sans-serif !important;
        font-size: 14px !important;
    }

    // Ebison on 14/Mar/2022
    .mat-row:hover {
        background-color: var(--matRowHoverBackColor);
        cursor: pointer;
    }
}

.table-head-custom{
    padding: 0px 7px 10px 7px;
}

.table-head{
    padding: 0px 7px 10px 7px;
}

// Ebison on 12-Aug-2022 - Display entire tooltip message without truncating
.outbound-log-tooltip {
    word-break: break-all !important;
    white-space: normal !important;
}

//**********************************************************************//
.common-style {
    .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
        background-color: var(--alertopsOrange);
    }
    .mat-input-element { 
        box-sizing: border-box;
    }
    .dark-theme-card {
        .numbers p {
            color: rgba(0, 0, 0, 0.65);
        }
    }
    .page_loader {
        position: fixed;
        top: 30%;
        left: 50%;
    }
    .icon-edit {
        font-size: 16px !important;
        color: var(--linkButtonColor);
    }
    .edit_icon {
        color: var(--linkButtonColor);
    }
    .model-title {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
    }
    .title-name {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 30px;
        color: var(--formLabelTextColor);
        font-family: Roboto, sans-serif;
        font-size: 13px;
        margin-bottom: 0;
    }
    .group-name {
        font-family: Roboto, sans-serif;
        font-weight: 600;
    }
    .card-header {
        padding: 0px;
        font-weight: 600;
        color: #2C2F3E;
        font-size: 18px;
        border-bottom: none;
        .card-title {
            margin-bottom: 0;
        }
    }
    .card-label {
        position: relative;
        font-size: 20px;
        margin-bottom: 5px;
    }
    .card-label:after {
        height: 4px;
        width: 40px;
        background: var(--linkButtonColor);
        display: inline-block;
        position: absolute;
        bottom: -5px;
        content: '';
        left: 0;
    }
    .breadcrumb-btn-box {
        color :var(--linkButtonColor);
        border-radius: 5px;
    }
    .btnui, .breadcrumb-btn-box, .edit-box-md {
        font-size: 11px;
        padding: 4px 10px;
        background-color: var(--smallAddBackColor);
        font-weight: 450;
    }
    .link {
        cursor: pointer;
    }
    .action_icon {
        height: 20px !important;
        line-height: 20px;
        font-size: 16px !important;
    }
    .form-control {
        background-color: transparent !important;
        border: 2px solid #f2f2f2;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        color: var(--editModeInputTextColor);
        font-family: Roboto, sans-serif;
        // padding:0px 20px;
        letter-spacing: normal;
    }
    .form-control.ep-addrole-user-border{
        border: 2px solid#e2e2e2;
    }
    .mat-select {
        background-color: transparent;
        border: 2px solid #f2f2f2;
        border-radius: 8px !important;
        font-size: 14px;
        font-weight: 500;
        color: var(--editModeInputTextColor);
        padding: 12px 12px;
        font-family: Roboto, sans-serif;
    }
    .card-body{
        padding: 1rem 0px;
        .form-control {
            &.form-control-solid {
                background-color: transparent;
                border: 2px solid #f2f2f2;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 400;
                color: var(--editModeInputTextColor);
                font-family: Roboto, sans-serif;
            }
        }
        .mat-form-field {
            background-color: transparent;
            border: 2px solid #f2f2f2;
            border-radius: 8px !important;
            font-size: 14px;
            font-weight: 500;
            color: var(--editModeInputTextColor);
            padding: 0px 15px;
            height: 46px;
            font-family: Roboto, sans-serif;
            width: 100%; // input, mat select width
        }
        .mat-select {
            padding: 12px 12px;
        }
        form {
            .mat-input-element  {
                box-sizing: border-box;
            }
        }
    }
    .mycard {
        margin-top: -6px !important;
    }
    .font-normal {
        font-weight: normal !important;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        color: var(--formValueTextColor);
        font-family: Roboto, sans-serif;
        font-size: 14px;
        overflow: hidden;
        word-break: break-word;
    }
    .bg-green, .bg-red {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        .mat-icon {
            height: 20px !important;
            line-height: 20px;
        }
    }
    .bg-green {
        background: #2CC44125;
    }
    .bg-red {
        background: #F1535325;
    }
    .width180{
        width: 180px !important;
        overflow: hidden;
    }
    .btn-link {
        background: none;
        border: none;
        font-size: 14px;
        color: var(--linkButtonColor);
        text-decoration: none;
        // display: flex;
        line-height: 30px;
        padding: 0px !important;
        font-weight: 600;
        font-family: Roboto, sans-serif;
        text-transform: uppercase;
        &:disabled {
            opacity: 0.4;
            color: var(--linkButtonColor);
        }
        @extend .btn-add; // button hover
        .mat-select-value { //Bulk add button
            span {
                color: var(--linkButtonColor) !important;
            }
        }
        .mat-icon {
            height: 20px !important;
            line-height: 20px;
            margin-left: -8px;
        }
    }
     
    .btn-link.mat-button {
        .mat-icon {
            position: relative;
            top: -1px;
        }
    }
    .notification-button { // Popup action buttons
        text-align: center;
        justify-content: center;
        margin: 30px 0px 0px;
    }
    .bt-background {  // submit button
        background: #2C2F3E;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
    }
    .bt-border { // cancel button
        color: #2C2F3E;
        text-transform: uppercase;
    }
    .btn-link.mat-button-disabled {
        background-color: transparent !important;
    }
    .mat-table {
        .mat-button-disabled {  // edit, delete icon background color in Mat table
            background-color: transparent !important;
        }
    }
    .bt-add-another.mat-button-disabled, .bt-border.mat-button-disabled,
    .bt-background.mat-button-disabled {
        color: rgba(0, 0, 0, 0.25);
    }
    .bt-add-another {
        text-transform: uppercase;
        color: #2C2F3E;
    }
    .bt-add-another:hover:enabled  { //  Add user popup
        background: #2C2F3E !important;
        color: #fff !important;
    }
    .testClass {
        .mat-form-field-infix {
            border: none;
            border-radius: 4px;
            background: #f2f2f2;
            height: auto;
            padding: 0 !important;
        }
        .mat-form-field-underline{
            display: none;
        }
        .mat-form-field-label{
            top: 22px;
            left: 11px;
            background-color: #ffffff;
            padding: 0px 11px;
            width: auto;
        }
    }
    .greybox { //search input
        background-color: #f2f2f2 !important;
        border: none;
    }
    .grey-back {
        background-color: grey !important;
    }
    .icon-clear-filter, .icon-search { // Clear search icon
        position: absolute;
        right: 15px;
        top: 50%;
        display: inline-block;
        margin-top: -11px;
        color: var(--linkButtonColor);
        font-size: 25px !important;
        cursor: pointer;
    }
    .spinner_div {
        position: absolute !important;
        top: 30%;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        justify-content: center;
        display: flex;
    }
    .tbl-action-btns {
        .mat-button-disabled {
            background-color: transparent !important;
        }
    }
    .add-popup-close { //Add user, workflow etc popup close btn
        cursor: pointer; 
        float: right;
    }
    .search-icon { // popup search icon
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
    }
    .width50 {
        width: 50%;
    }
    .example-header .form-control {
        padding: 0px 25px 0px 10px !important;
    }
    .icon-light { // popup search icon color
        color:  var(--linkButtonColor);
        font-size: 22px !important;
        position: absolute;
        right: 8px;
        top: 13px;
    }
    .p-relative{
        position: relative;
    }
    .filter-btn {
        height: 40px;
        line-height: 18px;
        font-family: Roboto, sans-serif;
    }
    .filter-dropdn {
        height: 40px;
        line-height: 13px !important;
        padding: 10px;
        margin-left: 3px;
    }
    .btn-color { //filter btn alert list, dashboard, maintenance window
        background: none;
        padding: 10px 18px !important;
        color: var(--linkButtonColor);
        border-radius: 8px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid var(--linkButtonColor);
        margin-left: 14px;
        opacity: 0.4;
    }
    .btn-color:hover, .btn-active, .btn-color.active  {
        border: 1px solid var(--linkButtonColor);
        color: var(--linkButtonColor);
        opacity: 1;
    }
    .w100 {
        width: 100%;
    }
    .mat-select-value {
        span {
            font-weight: 500;
            color: rgba(53, 56, 77, .7);
        }
    }
    .table-list-no-records-msg {
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        border-bottom: 1px solid rgb(0,0,0, 0.12);
        margin: 0 7px 0 7px;
        padding-bottom: 20px;
    }
    .chart-no-records {
        position: relative;
        text-align: center;
    }
    .ptb-6px {
        padding: 6px 0;
    }
    .mat-icon-drag-indicator { //esc-policy-cm-list
        height: 19px !important;
        width: 19px !important;
    }
    .mat-checkbox {
        font-size: 13px;
        font-family: Roboto, "Helvetica Neue", sans-serif;
    }
    .mat-checkbox-disabled {
        opacity: 0.6;
    }
    .edit-box-md { //Alert list, integration url mapping url test copy btn
        color :var(--linkButtonColor);
        border-radius: 5px;
        cursor: pointer;
        text-transform: uppercase;
    }
    .edit-box-sm { 
        color :var(--linkButtonColor);
        font-size: 10px;
        background-color: var(--linkButtonHoverColor);
        border-radius: 5px;
        padding: 4px 5px;
    }
    .error-msg {
        color: red;
        font-size: 12px;
    }
    .error-border {
        border-color: #ff8080 !important;
    }
    .table-scroll {
        .table-head {
            .mat-header-cell {
                font-size: 14px;
                font-weight: 500;
                color: #000;
            }
        }
        .table-head-custom {
            scrollbar-width: thin;
            scrollbar-color: #2c2f3e #e1e1e1;
            .mat-icon-button {
                width: 30px !important;
            }
            .mat-button-disabled, .button-disabled {
                .mat-icon {
                    color: rgba(63, 81, 181, 0.26);
                }
            }
            .mat-column-Action, .mat-column-action, .mat-column-actions, .mat-column-in_maintenance {
                .mat-icon {
                    vertical-align: sub;
                    color: var(--linkButtonColor);
                }
                .mat-icon-button {
                    padding-right: 5px;
                }
            }
            .mat-slide-toggle.mat-primary.mat-checked {
                .mat-slide-toggle-bar {
                    background-color: rgba(63, 81, 181, 0.54);
                }
                .mat-slide-toggle-thumb {
                    background-color: var(--linkButtonColor);
                }
            }
            .notification-button {
                .mat-icon-button {
                    width: 30px !important;
                    text-align: center !important;
                } 
            }
        }
        .three-dots-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        ::-webkit-scrollbar {
            width: 5px;
            max-height:5px; 
            height: 5px;
        }
          
        /* Track */
        ::-webkit-scrollbar-track {
          background: var(--scrollbarBackColor);
          max-height:5px; 
          height: 5px;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: var(--scrollbarColor);
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: var(--scrollbarColor); 
        }
    }
    .table-scroll.non-clickable {
        mat-row:hover{
            background-color: rgba(0, 0, 0, 0.04) ;
            cursor: default;  
        }
    }
    .btnui { // Alert list Assign/Close btn
        border: none;
        color: var(--linkButtonColor);
        border-radius: 4px;
        margin-top: 5px;
        font-weight: 500;
        &:disabled {
          opacity: 0.4;
          cursor: default;
        }
    }
    .btn-add {
        border: none;
        color: var(--linkButtonColor);
        border-radius: 4px;
        padding: 0 10px !important;
    }
    .btn-add:hover {
        background: var(--linkButtonColor);
        color: #fff;
        .icon-edit {
            color: #fff;
        }
    }
    .blu-icon {
        color: var(--linkButtonColor);
    }
    .mat-button-disabled {
        background-color: rgba(0, 0, 0, 0.12);
    }
    .mat-icon-button.mat-button-disabled {
        background-color: transparent;
        opacity: 0.6;
    }
    .condition-table-heading {
        float: left;
        font-size: 14px;
        line-height: 30px;
    }
    .ug-list {
        .title-name {
            font-weight: 600;
            font-size: 14px;
            margin: 12px 0;
            height: auto;
            padding: 7px;
        }
    }
    .mat-slide-toggle.mat-disabled, .toggle-label {
        opacity: .38;
        font-size: 16px;
    }
    .incident-detail {
        border: 1px solid var(--matRowBorderColor);
        margin-bottom: 10px;
        border-radius: 8px;
        .table {
            tr {
                border-bottom: 0.5px solid rgb(0, 0, 0, 0.1);
            }
        }
        &:hover {
            background-color: var(--matRowHoverBackColor);
        }
    }
    .cal-week-view, .cal-month-view {
        background-color: transparent;
    }
    .usage-text {
        font-size: 14px;
        font-weight: 410;
        color :var(--linkButtonColor);
    }
    a.usage-text:hover {
        color :var(--linkButtonColor);
    }
    .page-action {
        text-align: left !important;
    }
    .modal-action {
        text-align: right;
    }
    .info-icon {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        font-size: 12px !important;
    }
    .mat-button-toggle-appearance-standard:not(.mat-button-toggle-checked) {
        background: transparent;
    }
    .btn-border.active,
    .btn-blue {
        border: 1px solid var(--linkButtonColor);
        color: var(--linkButtonColor) !important;
        .mat-icon {
            height: 18px !important;
            line-height: 18px !important;
        }
    }
    .btn-subscription {
        border-color: var(--linkButtonColor);
        color: var(--linkButtonColor);
        background-color: transparent;
    }
    .btn-subscription.active, .btn-subscription:hover {
        background-color: var(--linkButtonColor);
    }
    .deleteIcon {
        color: var(--linkButtonColor);
    }
    .quickstart-escalation {
        strong.model-title {
            font-size: 14px;
        }
    }
    .mat-expansion-panel-header {
        font-size: 16px;
        &:hover {
            background: var(--matRowHoverBackColor);
        }
    }
    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
        background: var(--matRowHoverBackColor);
    }
    span.step-numeric.end-circle:after {
        display: block !important;
    }
    .filter-btn:hover {
        background-color: var(--matRowHoverBackColor);
        border-color: var(--matRowHoverBackColor);
    }
    .mat-list-item-content {
        .mat-button-disabled {
            opacity: 0.4;
        }
    }
    // NgbDatepicker style
    button.btn.btn-link.btn-sm.ngbUp, button.btn.btn-link.btn-sm.ngbdown {
        color: var(--linkButtonColor);
        opacity: 0.4;
        &:hover {
            background: none !important;
            background-color: none !important;
            opacity: 1;
        }
    }
    button.btn.btn-link.btn-sm.ngbUp {
        position: absolute !important;
        top: 0px !important;
        right: 0px !important;
        height: 23px !important;
        border-radius: 5.5px;
        margin-right: 1.5px;
        margin-top: 1.5px;
      }
      button.btn.btn-link.btn-sm.ngbdown {
          position: absolute;
          bottom: 0px;
          right: 0px;
          height: 23px;
          border-radius: 5.5px;
          margin-right: 1.5px;
          margin-bottom: 1.5px;
      }
      .ngb-tp-chevron::before {
          top: -4px !important;
          font-size: 10px !important;
      }
      .ngb-tp-chevron.bottom:before {
          transform: rotate(135deg) !important;
          top: -6px !important;
          font-size: 10px !important;
      }
      //Esc section dessign
    .escalation-policy-member-role-section .stepper-slide .step-left-card .mat-card,
    .service-status-subscriber-main, .right-side-preview {
        background: var(--escActiveSectionBack);
    }
    .mat-comp-list, .escalation-policy-member-role-section .mat-ver-stepper .mat-list {
        .mat-list-item.active {
            background: var(--escActiveSectionBack);
            .form-control {
                border-color: #e2e2e2;
            }
        }
    }
    .mat-comp-list {
        .mat-list-item-content {
            font-size: 14px;
        }
        .mat-list-values {
            cursor: default;
            font-size: 14px;
        }
        .mat-list-item {
            margin: 0;
        }
    }
    .component-header {
        margin-top: 15px;
        font-size: 12px;
        font-weight: 500;
        margin-left: 0;
        margin-right: 0;
    }
    .ss-add-subscriber-form {
        .form-control {
            border-color: #e2e2e2;
        }
    }
    .service-status-subscriber-main {
        .card {
            background-color: transparent;
            .card-header {
                background-color: transparent;
            }
        }
    }
    .service-status-component-list-main {
        .sec-title {
            text-align: center;
            display: block;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 5px;
        }
    }
    .right-side-preview {
        .subscribers-table {
            max-height: 275px;
            overflow: auto;
        }
    }
    // Esc section design
    .w-60 {
        width: 60%;
    }
    .w-70 {
        width: 70%;
    }
    .w-80 {
        width: 80%;
    }
    .w-90 {
        width: 90%;
    }
    .w-100 {
        width: 100%;
    }
    .circle {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        margin-right: 10px;
        background-color: var(--alertopsOrange);
    }
    .custom-date-input {
        font-weight: 500;
    }
    .quick-start-section {
        .btnui {
            font-size: 12px;
            text-transform: uppercase;
        }
    }
    .ql-editor.ql-blank::before {
        -webkit-text-fill-color: #949494;
        caret-color: #3f51b5;
        animation: cdk-text-field-autofill-end 0s 1ms;
    }
    .add-btn-line-height {
        line-height: 20px;
    }
    .cxselection {
        font-size: 16px !important;
        font-family: Roboto, Helvetica, sans-serif;
    }
    .cal-week-view .cal-header.cal-weekend span {
        color: #3f4452 ;
    }
    .temp-card {
        &::-webkit-scrollbar {
            width: 5px;
            max-height:5px; 
            height: 5px;
        }
          
        /* Track */
        &::-webkit-scrollbar-track {
          background: var(--scrollbarBackColor);
          max-height:5px; 
          height: 5px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: var(--scrollbarColor);
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: var(--scrollbarColor); 
        }
    }
    .link-text{
        a {
            color: #303030;
        }a:hover{
            color: #4899FF;
        }
    }
}
.report-alert-assignment-template-detail-wrapper,
.report-timeto-ack-template-detail-wrapper,
.report-timeto-res-template-detail-wrapper {
    .mat-spinner {
        position: absolute;
        top: 40%;
        z-index: 99;
    }
}
.mat-menu-panel {
    min-height: fit-content !important;
}
.split-btn, .split-button-toggle {
    text-transform: uppercase;
    font-size: 12px;
}
.mat-menu-item.split-btn {
    height: 29px !important;
    line-height: 24px !important;
}
.split-button-toggle {
    border: none !important;
    .mat-button-toggle {
        .mat-icon {
            vertical-align: text-top;
        }
    }
    .split-action {
        padding: 4px 22px;
    }
    .btnui.mat-button-toggle {
        border-radius: unset;
        margin-top: 0;
        height: 26px;
        .mat-button-toggle-label-content {
            line-height: 20px;
            padding: 0;
        }
    }
    .btnui.drop-down-button {
        padding: 3px;
    }
    .mat-button-toggle-checked.mat-button-toggle-appearance-standard.btnui,
    .mat-button-toggle-appearance-standard.btnui {
        background: var(--smallAddBackColor);
    }
}
.mat-button-toggle-group-appearance-standard.split-button-toggle .mat-button-toggle + .mat-button-toggle {
    border-color: #c8c8c8 !important;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 40px;
}
.common-style.form {
    .mat-select,.form-control {
        background-color: transparent;
        border: 2px solid #f2f2f2;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        color: var(--editModeInputTextColor);
    }
    .mat-input-element { // Add user, workflow popup
        padding: 10px !important;
        box-sizing: border-box;
    }
    .mat-select {
        padding: 12px 15px;
    }
}
::ng-deep {
    .common-style {
        .mat-tab-header {
            background: rgba(0, 0, 0, 0.1);
            width: 100%;
        }
        .mat-form-field-appearance-legacy {
            .mat-form-field-wrapper{
                padding-bottom: 0;
            }
            .mat-form-field-underline{
                bottom: 0;
            }
        }
        .mat-form-field{
            width: 100%;
        }
        .mat-form-field-underline{
            display: none;
        }
        .mat-select-value {
            span {
                color: var(--editModeInputTextColor) !important;
            }
        }
        .mat-select-arrow {
            color: rgba(0, 0, 0, 0.38);
        }
        .mat-button{
            padding-right: 0px !important;
        }
        .time-to-ack-filter, .oncallnow-filter, .open-alerts-filter, .analysis-filter, 
        .alert-assignment-filter, .alert-summary-filter, .time-to-resolve-filter {
            .mat-spinner {
                position: fixed;
                top: 45%;
                z-index: 999999;
            }
        }
    }
}
.mat-select-panel, app-alert-delay-group-inbound, .workflow-action-edit,
.workflow-access-comp .mat-table, .mat-dialog-container, textarea.form-control,
.right-side-preview .subscribers-table, .cdk-overlay-pane {
    &::-webkit-scrollbar {
        width: 5px;
        max-height:5px; 
        height: 5px;
    }
      
    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--scrollbarBackColor);
      max-height:5px; 
      height: 5px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--scrollbarColor);
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--scrollbarColor); 
    }
}
@media (min-width: 200px) and (max-width: 768px) { /* For mobile phones: */
    .cdk-global-overlay-wrapper {
        overflow: auto;
        pointer-events: auto;  
    }
    .common-style {
        .card-header {
            .group_name_label {
                font-size: 12px !important;
            }
        }
    }
}
@media (min-width: 200px) and (max-width: 768px) { /* For mobile phones: */
    .cdk-global-overlay-wrapper {
        overflow: auto;
        pointer-events: auto;  
    }
    .common-style {
        .card-header {
            .group_name_label {
                font-size: 12px !important;
            }
        }
    }
}
